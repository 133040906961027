import { useEffect, useState } from "react";
import { FC } from 'react';

export interface SubmitButtonInterface {
  form: {
    handleSubmit: () => {},
    values: {}
  },
  state: {},
  label: string,
  isLoading: boolean,
  disabled: boolean
}


export const SubmitButton: FC<SubmitButtonInterface> = ({
  label,
  disabled,
  state,
  isLoading = false,
  form: { handleSubmit, values },
}) => {

  return (
    <>
      {state === null && 
        <button
          type='button'
          className='btn btn-color-success btn-success text-white btn-sm me-5'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
          onClick={() => handleSubmit()}
          disabled={state || isLoading ? true : false}
        >
          <div className="d-flex align-items-center">
            <span>{label}</span>
            {isLoading && (
              <span className="spinner-border spinner-border-sm text-light ms-2"></span>
            )}
          </div>

        </button>
      }

    </>

  );
}