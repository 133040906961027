// Import the RTK Query methods from the React-specific entry point
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getToken, getGraphAccessToken } from '../../auth/redux/authSlice'
import { RootState } from '../../../redux/store'
import { createSelector } from '@reduxjs/toolkit'
import { serviceConfigInterface, serviceRunsDataInterface } from '../interfaces/serviceRunsInterfaces'

const backend_endpoint = process.env.REACT_APP_BACKEND_ENDPOINT

export const cloudAppAPI = createApi({
  // The cache reducer expects to be added at `state.api` (already default - this is optional)
  reducerPath: 'api',
  tagTypes: ['Post','AllServices'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${backend_endpoint}`, prepareHeaders: (headers, { getState }) => {
      const stateData = getState() as RootState
      const token = getToken(stateData)
      const graphToken = getGraphAccessToken(stateData)
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
        headers.set('AUTHORIZATIONGRAPH', `${graphToken}`)
      }
      return headers
    },
  }),
  // refetchOnFocus: true,
  endpoints: builder => ({
    getAllServiceRuns: builder.query<Array<serviceRunsDataInterface>, {dateRange: string}>({
      query: (body) => ({ url: `serviceruns/`, method: 'POST', body: body }),
      // keepUnusedDataFor: 60 * 60 * 24 * 365 * 10,
      providesTags:['AllServices']
    }),
    getServiceConfig: builder.query<Array<serviceConfigInterface>, {}>({
      query: () => `serviceconfig/`,
      keepUnusedDataFor: 60 * 60 * 24 * 365 * 10
    }),
    connectDB: builder.query<{tabledata: Array<{ [key: string]: string; }>, user_exist: boolean}, {}>({
      query: (body) => ({ url: `dbquery/`, method: 'POST', body: body }),
      providesTags: ['Post']
    }),
    asyncConnectDB: builder.query<{tabledata: Array<{ [key: string]: string; }>, user_exist: boolean}, {}>({
      query: (body) => ({ url: `asyncdbquery/`, method: 'POST', body: body }),
      providesTags: ['Post'],
      keepUnusedDataFor: 0
    }),
    getCurrentServiceRuns: builder.query<Array<serviceRunsDataInterface>, number[]>({
      query: (body) => ({ url: `currentserviceruns/`, method: 'POST', body: body }),
      keepUnusedDataFor: 60 * 60 * 24 * 365 * 10,
      providesTags:['AllServices']
    }),
    startNewServiceRun: builder.mutation({
      query: serviceParams => ({
        url: 'serviceruncreate/',
        method: 'POST',
        body: serviceParams
      }),
      invalidatesTags: ['Post']
    }),
    approveServiceRun: builder.mutation({
      query: serviceParams => ({
        url: 'servicerunapprove/',
        method: 'POST',
        body: serviceParams
      }),
      invalidatesTags: ['Post']
    })
  })
})
export const serviceConfig = cloudAppAPI.endpoints.getServiceConfig.select({})
export const getServiceConfigNames = createSelector(serviceConfig, (functionConfigs) => functionConfigs.data?.map((entry:{service_descriptive_name: string}) => (entry.service_descriptive_name)))


export const { useGetAllServiceRunsQuery, useGetCurrentServiceRunsQuery, useStartNewServiceRunMutation, useGetServiceConfigQuery, useConnectDBQuery, useApproveServiceRunMutation, useLazyConnectDBQuery, useAsyncConnectDBQuery } = cloudAppAPI
