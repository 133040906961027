import { useServiceRedux } from '../../app/modules/ServiceRuns/redux/useServiceRedux'
import { MenuItem } from './MenuItem'
import { serviceConfig } from '../../app/modules/ServiceRuns/redux/cloudAppAPI'
import { useAppSelector } from "../../app/redux/store";
import { useGlobalContext } from '../../app/components/GlobalContext/GlobalContext'
import { menus } from '../../_metronic/layout/components/header/header-menus/MenuInner'

export const Menu = () => {

  const { bu } = useGlobalContext();
  const { data } = useAppSelector((state) => serviceConfig(state))
  const { serviceConfigNames } = useServiceRedux();
  const isAllowedServiceInBu = (services: string[]) => {
    return data && services.some(service => data.filter(entry => entry.service_descriptive_name === service).length > 0 && data.filter(entry => entry.service_descriptive_name === service)[0].buConfig.map(entry => entry.value).includes(bu))
  }


  return (
    <>
      {
        menus.map(menuItem => {
          const menuAllowed = menuItem.isBURestricted ? isAllowedServiceInBu(menuItem.services.map(service => service.path)) : serviceConfigNames?.some(ser => menuItem.services.map(service => service.path).includes(ser))
          return (
            <>
              {menuAllowed && <MenuItem topic={menuItem.title} key={menuItem.title}/>}
            </>
          )

        })
      }
    </>
  )
}