import React, { useState, useContext } from "react";
import { WithChildren } from '../../../../_metronic/helpers/react18MigrationHelpers';
import { tableParamsInterface } from '../../../components/table/interfaces'
import { serviceConfigInterface } from '../interfaces/serviceRunsInterfaces'
import { useLazyConnectDBQuery } from "../../../modules/ServiceRuns/redux/cloudAppAPI";
export interface searchFilterObjectInterface {
  daterange: [Date | null, Date | null],
  searchText: string
}
type GridSortDirection = 'asc' | 'desc' | null | undefined

type ServiceRunsContextInterface = {
  filterObject: searchFilterObjectInterface,
  setFilterObject: React.Dispatch<React.SetStateAction<searchFilterObjectInterface>>,
  tableParams: tableParamsInterface,
  setTableParams: React.Dispatch<React.SetStateAction<tableParamsInterface>>,
  currentTableItems: number[],
  setCurrentTableItems: React.Dispatch<React.SetStateAction<number[]>>,
  currentServiceConfig: serviceConfigInterface,
  setCurrentServiceConfig: React.Dispatch<React.SetStateAction<serviceConfigInterface>>,
  setCommonQueries: React.Dispatch<React.SetStateAction<{ [key: string]: { [key: string]: string; }[]; } | undefined>>,
  commonQueries: { [key: string]: { [key: string]: string; }[]; } | undefined,
  trigger: (par: {}) => ({}),
  result: any,
  errorMessage: string,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  queryTrigger: boolean,
  setQueryTrigger: React.Dispatch<React.SetStateAction<boolean>>,
  setPaginationModel: React.Dispatch<React.SetStateAction<{page: number, pageSize: number}>>,
  setSortModelData: React.Dispatch<React.SetStateAction<{field: string, sort: GridSortDirection}[]>>,
  sortModelData: { field: string, sort: GridSortDirection }[],
  paginationModel: {page: number, pageSize: number}
}
const ServiceRunsContext = React.createContext<ServiceRunsContextInterface>({
  filterObject: {
    searchText: "",
    daterange: [null, null]
  },
  tableParams: {
    pageNumber: 1,
    pageSize: 10,
    sortOrder: 'desc',
    sortField: 'registered'
  },
  currentTableItems: [],
  setCurrentTableItems: () => { },
  setTableParams: () => { },
  setFilterObject: () => { },
  currentServiceConfig: { service_descriptive_name: "", allowed_groups: "", field_config: [], page_topic: "", action_buttons_config: [], buConfig: [{ name: "Choose BU", value: "default" }] },
  setCurrentServiceConfig: () => { },
  setCommonQueries: () => { },
  commonQueries: {},
  trigger: (par: {}) => ({}),
  result: {},
  errorMessage: "",
  setErrorMessage: () => { },
  queryTrigger: false,
  setQueryTrigger: () => { },
  setPaginationModel: () => { },
  setSortModelData: () => { },
  sortModelData: [{ field: 'registered', sort: 'desc' as GridSortDirection }],
  paginationModel:{page: 0, pageSize: 10}
});

export function useServiceRunsContext() {
  return useContext(ServiceRunsContext);
}

export const ServiceRunsProvider: React.FC<WithChildren> = (props) => {
  const [filterObject, setFilterObject] = useState<searchFilterObjectInterface>({
    searchText: "",
    daterange:[new Date(new Date().setDate(new Date().getDate() - 1)), new Date()]
  })
  const [currentTableItems, setCurrentTableItems] = useState<number[]>([])
  const [tableParams, setTableParams] = useState<tableParamsInterface>({
    pageNumber: 1,
    pageSize: 10,
    sortOrder: 'desc',
    sortField: 'registered'
  })
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const [sortModelData, setSortModelData] = useState([{ field: 'registered', sort: 'desc' as GridSortDirection}]);
  const [currentServiceConfig, setCurrentServiceConfig] = useState<serviceConfigInterface>({service_descriptive_name:"", allowed_groups:"", field_config:[], page_topic:"", action_buttons_config: [],buConfig: [{ name: "Choose BU", value: "default" }]})
  const [commonQueries, setCommonQueries] = useState<{ [key: string]: { [key: string]: string; }[]; } | undefined>()
  const [trigger, result] = useLazyConnectDBQuery();
  const [errorMessage, setErrorMessage] = useState("");
  const [queryTrigger, setQueryTrigger] = useState(false);
  // const { data = [], error, isFetching, isLoading } = result;

  return <ServiceRunsContext.Provider
    value={{
      filterObject,
      setFilterObject,
      tableParams,
      setTableParams,
      currentTableItems,
      setCurrentTableItems,
      currentServiceConfig,
      setCurrentServiceConfig,
      setCommonQueries,
      commonQueries,
      trigger,
      result,
      errorMessage,
      setErrorMessage,
      queryTrigger,
      setQueryTrigger,
      setPaginationModel,
      setSortModelData,
      sortModelData,
      paginationModel
    }}>
    {props.children}
  </ServiceRunsContext.Provider>
}

export default ServiceRunsContext;