import React, { useState, useContext } from "react";
import { WithChildren } from '../../../_metronic/helpers/react18MigrationHelpers';


type GlobalContextInterface = {
    bu: string,
    setBU: React.Dispatch<React.SetStateAction<string>>,
    alertMessage: string,
    setAlertMessage: React.Dispatch<React.SetStateAction<string>>,
}
const GlobalContext = React.createContext<GlobalContextInterface>({
    bu: "default",
    setBU: () => { },
    alertMessage: "",
    setAlertMessage: () => { },
});

export function useGlobalContext() {
    return useContext(GlobalContext);
}

export const GlobalContextProvider: React.FC<WithChildren> = (props) => {
    const [bu, setBU] = useState<string>("default")
    const [alertMessage, setAlertMessage]  = useState<string>("")

    return <GlobalContext.Provider
        value={{
            bu,
            setBU,
            alertMessage,
            setAlertMessage
        }}>
        {props.children}
    </GlobalContext.Provider>
}

export default GlobalContext;